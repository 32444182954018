<template>
  <AppCard class="volt-card" centered>
    <template #header>
      <AppCardHeader class="volt-header">
        <template v-if="isPayTo" #left>
          <AppBackButton
            class="back-button"
            name="Go Back"
            analytics-name="send-money-volt-back"
            icon="<"
            @click="goBackOneStep"
          />
        </template>
        <h2>{{ $t('SendMoneyVolt.Title').value }}</h2>
      </AppCardHeader>
    </template>
    <AppSpinnerBig :loading="isLoading" />
    <div id="volt-payment-component">
      <!-- Volt Drop-in component will be rendered here -->
    </div>
  </AppCard>
</template>

<script>
import { defineComponent, ref, onBeforeMount, onBeforeUnmount } from '@vue/composition-api'

import {
  useAppStore,
  useEnvStore,
  usePaymentsStore,
  useI18nStore,
  useAuthStore,
  useSendMoneyStore,
  useAnalyticsStore,
} from '@galileo/stores'

import { AppSpinnerBig, AppCard, AppCardHeader, AppBackButton } from '@oen.web.vue2/ui'
import { useRouter } from '@galileo/composables/useRouter'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'
import useOpenBanking from '@galileo/composables/useOpenBanking'
import { VOLT } from '@galileo/constants/sendMoneyFlow.const'

export default defineComponent({
  components: {
    AppSpinnerBig,
    AppCard,
    AppCardHeader,
    AppBackButton,
  },
  props: {},
  events: [],
  setup(props, { emit }) {
    const i18nStore = useI18nStore()
    const { $t } = i18nStore
    const appStore = useAppStore()
    const authStore = useAuthStore()
    const isLoading = ref(false)
    const isProduction = useEnvStore().isProduction
    const router = useRouter()
    const sendMoneyStore = useSendMoneyStore()
    const analyticsStore = useAnalyticsStore()
    const { isPayTo, cancelCheckout } = useOpenBanking()
    let channel = null

    //NOTIFY this tab to be closed.
    try {
      channel = new BroadcastChannel('volt-checkout-complete')

      channel.onmessage = async (event) => {
        if (event.data.closeWindow) {
          channel.postMessage('focus-tab')
          window.close()
        }
      }
    } catch (ex) {
      appStore.logException(ex)
    }

    const mode = isProduction ? 'production' : 'sandbox' // Set to sandbox or production as required

    let startRedirection = false

    //INIT VOLT CHECKOUT
    async function init() {
      const response = await usePaymentsStore().getVoltSession()
      const volt = new window.Volt({ mode })

      if (response?.errorCode === VOLT.ERROR.SESSION_EXISTS) {
        startRedirection = true
        channel.postMessage({
          voltCheckoutComplete: true,
          voltCheckoutStatus: {
            status: VOLT.STATUS.COMPLETED,
          },
        })
      }

      // Create payment container
      const paymentContainer = volt.payment({
        payment: response.data, // Payment response from the server, containing id and token.
        language: i18nStore.localeLanguagePart, // optional - ISO 639-1 - example: 'en'
        country: authStore.user.country,
        // country: 'GB', // optional - ISO 3166 - example: 'GB'
        theme: {
          fontColor: '#313F5B',
          linkColor: '#006CE0',
          backgroundColor: '#ffffff',
          inputTextColor: '#000000',
          inputBorderColor: '#006CE0',
          inputBackgroundColor: '#ffffff',
          inputBorderRadius: '8px',
          payButtonTextColor: '#ffffff',
          payButtonBorderColor: '#006CE0',
          payButtonBackgroundColor: '#ffffff',
          payButtonBorderRadius: '8px',
          iconColor: '#636E82',
        },
      })
      // remove the spinner once mounted?
      isLoading.value = true

      // Fetch when the language is changed
      paymentContainer.on('languageChange', (e) => {
        console.log('Changed language to: ' + e.language)
      })
      // Fetch when the country is changed
      paymentContainer.on('countryChange', (e) => {
        console.log('Changed country to: ' + e.country)
      })
      paymentContainer.on('error', (e) => {
        console.log('Error ' + e.code + ' message: "' + e.message + '"')
      })

      paymentContainer.on('completed', () => {
        if (isPayTo.value) {
          sendMoneyStore.isAUvoltCompleted = true
          sendMoneyStore.voltCheckoutComplete = true
          router.replace('/creating')
        }
      })

      paymentContainer.on('failed', () => {
        if (isPayTo.value) {
          setTimeout(() => {
            router.replace('/send-money/failed')
          }, 2000)
        }
        console.log('Failed: ', e)
      })

      // Create payment component
      const paymentComponent = paymentContainer.createPayment({
        displayQRCode: true, // optional, default: true (requires displayPayButton = true)
        displayPayButton: true, // optional, default: true
        autoHeight: true, // optional, default: true
      })

      paymentComponent.mount('#volt-payment-component')

      paymentContainer.on('ready', (e) => {
        //this doesn't remove the spinner on PayTo
        isLoading.value = false
      })

      paymentContainer.on('redirect', (e) => {
        startRedirection = true
        //uncomment to mock the redirection back for volt
        // testRedirectBackBehaviour()
        console.log('Redirecting: ', e)
      })

      isLoading.value = false
    }

    onBeforeMount(async () => {
      try {
        isLoading.value = true
        await appStore.loadScriptVolt()
        // Render Volt Embedded Checkout
        init()
      } catch (e) {
        console.error('Error loading Volt: ', e)
        isLoading.value = false
      }
      window.addEventListener('beforeunload', handleBeforeUnload)
    })

    const handleBeforeUnload = (e) => {
      if (!startRedirection) {
        e.preventDefault()
        e.returnValue = ''
        cancelCheckout()
      }
    }

    onBeforeUnmount(() => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    })

    const goBackOneStep = async () => {
      if (isPayTo.value) {
        await analyticsStore.track({
          event: SEGMENT_EVENTS.OPEN_BANKING_CANCELLED_TRIGGERED,
          traits: {
            sendMethod: sendMoneyStore.form.paymentMethod,
            accountType: authStore.user.customer.accountType,
          },
        })
        await router.replace({ name: 'SendMoneyVoltPaymentCancelled' })
      } else {
        cancelCheckout()
      }
    }

    //#region Test / Mocks
    //---------- T E S T S / M O C K S --------------------

    // mock complete status when volt redirects us back
    // can be used in redirect handler to test in localhost

    const testRedirectBackBehaviour = () => {
      sendMoneyStore.changeVoltCheckoutComplete(true, { status: VOLT.STATUS.COMPLETED })
    }

    //wait 6 minutes then notfy volt we finished it will
    //have a quote expired error form the server
    const testQuoteExpired = () => {
      const milliseconds = (h, m, s) => (h * 60 * 60 + m * 60 + s) * 1000

      const minutes = 6
      const minutesInMillis = milliseconds(0, 6, 15)
      setTimeout(testRedirectBackBehaviour, minutesInMillis)
    }
    //#endregion

    return {
      $t,
      isLoading,
      goBackOneStep,
      isPayTo,
    }
  },
})
</script>

<style scoped>
.volt-card {
  @screen sm {
    @apply max-w-3xl;
  }
}

#volt-payment-component {
  height: fit-content;
  min-height: 40vh;
}

.back-button {
  max-width: 48px;
}
.volt-header {
  @media (max-width: 600px) {
    @apply hidden;
  }
}
</style>
